import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   component: () => import( '../views/login/index.vue')
  // },
  {
    path: '/Risk',
    name: 'risk',
    component: () => import( '../views/tree/index.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import( '../views/401/index.vue')
  },
  {
    path: '/MSDS',
    name: 'MSDS',
    component: () => import( '../views/MSDS/index.vue')
  },

  {
    path: '/SelectingPoints',
    name: 'SelectingPoints',
    component: () => import( '../views/SelectingPoints/index.vue')
  },

  {
    path: '/Project',
    name: 'project',
    component: () => import( '../views/project/index.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import( '../views/demo/map.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
