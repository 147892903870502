import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: 7,
    path: ''
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const userInfoKey = 'userInfo'
export function getUserInfo() {
  return Cookies.get(userInfoKey)
}

export function setUserInfo(data) {
  return Cookies.set(userInfoKey, data, {
    expires: 0.5,
    path: ''
  })
}

export function removeUserInfo() {
  return Cookies.remove(userInfoKey)
}

const refreshKey = 'refresh'
export function getRefreshToken() {
  return Cookies.get(refreshKey)
}

export function setRefreshToken(data) {
  return Cookies.set(refreshKey, data, {
    expires: 0.5,
    path: ''
  })
}

export function removeRefreshToken() {
  return Cookies.remove(refreshKey)
}