import axios from 'axios';
import { ElMessage } from 'element-plus'
import {
  getToken,
  removeToken,
  setToken
} from '@/utils/auth';

const service = axios.create({
  timeout: 3000000
});

var httpError = false;
const waring = function(data){
  ElMessage({
    message: data,
    grouping: true,
    type: 'warning',
  })
} ;
// request 拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    if (!config.headers.Authorization) {
      //让每个请求携带token
      config.headers.Authorization = getToken();
    }
  } else {  // 没有token的情况下 一般为登录或者登录前的接口
    if (!config.headers.Authorization) {
      config.headers.Authorization = 'Basic c2t5em9uZ2hlc2h1anVndWFubGk6MTIzNDU2';
    }

  }
  return config;
}, (error) => {
  Promise.reject(error);
});
// respone 拦截器
service.interceptors.response.use(response => {
  // console.log(response)
  if (response.status == 200) {
    return response.data;
  } else {
    return Promise.reject('error');
  }
}, (error) => {
  const res = error.response;
  if (res) {
    // 401鉴权失败
    if (res.status == 401) {

    };
    // 400错误
    if (res.status == 400) {
      waring(res.data.message)
    };
    if (res.status == 404) {
      waring(res.data.message)
    };
    // 500错误
    if (res.status == 500) {
      waring(res.data.message)
    };
    if (res.status == 502) {
      waring(res.data.message)
    };
  }
  return Promise.reject(error);
});

export default service;
