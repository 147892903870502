import request from '@/utils/request';
// 列表
export function pageConditionAnd(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpProject/pageConditionAnd',
        method: 'post',
        data: params
    })
}
// 新增
export function doInsert(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpProject/doInsert',
        method: 'post',
        data: params
    })
}

// 编辑
export function doUpdate(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpProject/doUpdate',
        method: 'post',
        data: params
    })
}
// 删除
export function doDeleteById(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpProject/doDeleteById?id='+id,
        method: 'get',
    
    })
}
// ............msds............
// 列表
export function msdspageConditionAnd(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsds/pageConditionAnd',
        method: 'post',
        data: params
    })
}
export function msdsdoInsert(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsds/doInsert',
        method: 'post',
        data: params
    })
}
// 删除
export function msdsdoDeleteById(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsds/doDeleteById?id='+id,
        method: 'get',
    
    })
}

// 编辑
export function msdsdoUpdate(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsds/doUpdate',
        method: 'post',
        data: params
    })
}
// ............msds扩展............
// 列表
export function kzpageConditionAnd(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsdsother/pageConditionAnd',
        method: 'post',
        data: params
    })
}
// 添加
export function kzmsdsdoInsert(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsdsother/doInsert',
        method: 'post',
        data: params
    })
}
// 编辑
export function kzmsdsdoUpdate(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsdsother/doUpdate',
        method: 'post',
        data: params
    })
}
// 删除
export function kzmsdsdoDeleteById(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsdsother/doDeleteById?id='+id,
        method: 'get',
    
    }) 
}
// ............风险评估............

//查询项目
export function getproject(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpProject/customQuery',
        method: 'post',
        data: params
    })
}
// 气象树
export function tree(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPair/tree',
        method: 'get',
    
    })
}
//获取泄漏源强
export function getq(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/getq?A='+params.A+'&Cd=' + params.Cd+'&M=' + params.M+'&P=' + params.P +'&r=' + params.r+'&Rg=' + params.Rg+'&T=' + params.T,
        method: 'get',
    
    })
}
//计算接口
export function poolfiregs(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/poolfire?bc=' + params.bc +'&dmdt=' + params.dmdt+'&hc=' + params.hc+'&n=' + params.n+'&s=' + params.s+'&ρ0=' + params.ρ0+'&extime=' + params.extime,
        method: 'get', 
    })
}
//计算ble接口
export function poolfiregsble(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/bleve?bc=' + params.bc +'&W=' + params.W+'&method=' + params.method+'&q0=' + params.q0+'&extime=' + params.extime,
        method: 'get',
    })
}
//计算qt接口
export function poolfiregsqt(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/spread?airAngle=' + params.airAngle +'&airSpeed=' + params.airSpeed+'&Leakagetype=' + params.Leakagetype+'&q=' + params.q+'&RadiationLevel=' + params.RadiationLevel+'&time=' + params.time +'&x=' + params.x + '&y=' + params.y,
        method: 'get',
    })
}
//添加事故表
export function addpoolpir(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpoolfire/doInsert',
        method: 'post',
        data: params
    })
}
//添加ble事故表
export function addpoolpirble(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mbleve/doInsert',
        method: 'post',
        data: params
    })
}

//添加气体扩散事故
export function addpoolpirqt(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mgasleak/doInsert',
        method: 'post',
        data: params
    })
}
// 查询事故表总
export function seeaccidentall(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/accidentlist/customQuery' ,
        method: 'post',
        data: params
    })
}
//查询事故
export function seeaccident(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpoolfire/customQuery',
        method: 'post',
        data: params
    })
}
//查询ble事故
export function seeaccidentble(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mbleve/customQuery',
        method: 'post',
        data: params
    })
}
//计算气体
export function seeaccidentqtjs(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/spread',
        method: 'post',
        data: params
    })
}
//查询qt事故
export function seeaccidentqt(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mgasleak/customQuery',
        method: 'post',
        data: params
    })
}
//更新事故
export function upaccident(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpoolfire/doUpdate',
        method: 'post',
        data: params
    })
}


//更新事故
export function upaccidentqt(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mgasleak/doUpdate',
        method: 'post',
        data: params
    })
}
//更新ble事故
export function upaccidentble(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mbleve/doUpdate',
        method: 'post',
        data: params
    })
}
//删除事故
export function delsg(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpoolfire/doDeleteById?id=' + id ,
        method: 'get',
    })
}
//删除ble事故
export function delsgble(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mbleve/doDeleteById?id=' + id ,
        method: 'get',
    })
}
//删除qt事故
export function delsgqt(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mgasleak/doDeleteById?id=' + id ,
        method: 'get',
    })
}
// 获取公式
export function getgs(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/Formula/customQuery',
        method: 'post',
        data: params
    })
}
// 获取所有属性
export function csWhpMsdsother(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/Formula/customQuery',
        method: 'post',
        data:[
            {
              "column": "chemicalid",
              "type": "eq",
              "value": id
            }
          ]
    })
}
export function csWhpMsdsother2(id, name) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsdsother/getMsdsotherlist?id='+id+'&name='+name,
        method: 'get',
    })
}
// 查询压力值
export function gethjyl(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPair/customQuery',
        method: 'post',
        data: params
    })
}
// 查询物料值
export function getwl(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpMsds/customQuery',
        method: 'post',
        data: params
    })
}
// 添加基本信息
export function addjbxx(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPzzinfo/doInsert',
        method: 'post',
        data: params
    })
}
// 添加
export function qxdoInsert(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPair/doInsert',
        method: 'post',
        data: params
    })
}
// 删除
export function qxdoDeleteById(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPair/doDeleteById?id='+id,
        method: 'get',
    
    })
}
// 更新
export function qxdoUpdate(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPair/doUpdate',
        method: 'post',
        data: params
    })
}

// 设备列表查询
export function listing(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPzzinfo/customQuery',
        method: 'post',
        data: params
    })
}

// 新增装置
export function addlisting(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPzzinfo/doInsert',
        method: 'post',
        data: params
    })
}
// 更新装置
export function dolisting(params) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPzzinfo/doUpdate',
        method: 'post',
        data: params
    })
}

export function qtdoDeleteById(id) {
    return request({
        url: '/rest/APPService/uscu-gp/v1.0/csWhpPzzinfo/doDeleteById?id='+id,
        method: 'get',
    
    })
}

// 添加液体泄漏
export function ooze(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mliquidleak/doInsert',
        method: 'post',
        data: params
    })
}

// 获取液体泄漏
export function Obtainooze(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mliquidleak/customQuery',
        method: 'post',
        data: params
    })
}
export function deleteooze(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mliquidleak/doDeleteById?id='+id,
        method: 'get',
    
    })
}
export function doooze(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mliquidleak/doUpdate',
        method: 'post',
        data: params
    })
}
// 液体查询



export function cooze(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mliquidleak/customQuery',
        method: 'post',
        data: params
    })
}
// export function spreadss(params) {
//     /*          airAngle:null,
//                 airSpeed:null,
//                 Leakagetype:null,
//                 q:null,
//                 RadiationLevel:null,
//                 time:null,
//                 x:null,
//                 y:null */
//     return request({
//         url: `/rest/whp/qra/service/v1.0/model/spread?airAngle=${params.airAngle}&airSpeed=${}&Leakagetype=${}&`,
//         method: 'get',
        
//     })
// }

export function spreadx(params, yzq) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/spreadx?airAngle=' + params.airAngle +'&airSpeed=' + params.airSpeed+'&Leakagetype=' + params.Leakagetype+'&q=' + params.q+'&RadiationLevel=' + params.RadiationLevel+'&time=' + params.time +'&x=' + params.x + '&y=' + params.y + '&yzq=' + yzq,
        method: 'get',
    })
}
// 新增压力容器爆炸
export function ylbzdoInsert(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressurebz/doInsert',
        method: 'post',
        data: params
    })
}

// 压力容器爆炸查询
export function ylbzcustomQuery(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressurebz/customQuery',
        method: 'post',
        data: params
    })
}

// 编辑压力容器
export function ylbzdoUpdate(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressurebz/doUpdate',
        method: 'post',
        data: params
    })
}
export function ylbzdelete(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressurebz/doDeleteById?id='+id,
        method: 'get',
    
    })
}
export function Condensedphase2(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/Condensedphase?bc='+params.bc+'&qf='+params.qf+'&Wf='+params.Wf,
        method: 'get',
    
    })
}
// 凝聚相添加
export function Condensedphase(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/condensedphase/doInsert',
        method: 'post',
        data: params
    
    })
}
export function CodoDeleteById(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/condensedphase/doDeleteById?id='+id,
        method: 'get',
    })
}
// 查询凝聚相
export function Condenscha(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/condensedphase/customQuery',
        method: 'post',
        data: params
    
    })
}
// 更新凝聚相
export function CondoUpdate(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/condensedphase/doUpdate',
        method: 'post',
        data: params
    
    })
}
// 查询凝聚相
export function ConcustomQuery(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/condensedphase/customQuery',
        method: 'post',
        data: params
    
    })
}

// 喷射火新增
export function pshdoInsert(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/jetfire/doInsert',
        method: 'post',
        data: params
    })
}
// 喷射火编辑
export function pshdoUpdate(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/jetfire/doUpdate',
        method: 'post',
        data: params
    
    })
}
// 查询喷射火
export function pshcustomQuery(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/jetfire/customQuery',
        method: 'post',
        data: params
    
    })
}
// 删除喷射火    console.log(currentdata.value.types , pshtext.value)


export function pshdoDeleteById(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/jetfire/doDeleteById?id='+id,
        method: 'get',
    })
}
// 喷射火模型


export function pshjetfire(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/jetfire?bc='+params.bc+'&extime='+params.extime+'&hc='+params.hc+'&n='+params.n+'&q0='+params.q0+'&d0='+params.d0+'&pa='+params.pa+'&pg='+params.pg+'&tj='+params.tj,
        method: 'get',
    
    })
}

// 压力容器爆炸模型
export function pressurevessels(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/pressurevessels?bc='+params.bc+'&eg='+params.eg+'&ytmd='+params.ytmd+'&ytrj='+params.ytrj,
        method: 'get',
    
    })
}

export function ylrqsq(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressurebz/customQuery',
        method: 'post',
        data: params
    
    })
}
// tnot添加
export function tnodoInsert(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/tno/doInsert',
        method: 'post',
        data: params
    
    })
}
// tnot 编辑
export function tnodoUpdate(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/tno/doUpdate',
        method: 'post',
        data: params
    
    })
}

// tnot 查询
export function tnocustomQuery(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/tno/customQuery',
        method: 'post',
        data: params
    
    })
}

// TNo爆炸模型
export function modeltno(params) {
    return request({
        url: '/rest/whp/qra/service/v1.0/model/tno?bc='+params.bc+'&c0='+params.c0+'&p='+params.p+'&wc='+params.wc+'&Qf='+params.Qf,
        method: 'get',
    
    })
}

// 液化气查询
export function yhqcustomQuery(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressliquidleak/customQuery',
        method: 'post',
        data: params
    
    })
}
// 液化气添加
export function yhqdoInsert(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressliquidleak/doInsert',
        method: 'post',
        data: params
    
    })
}

// 液化气编辑
export function yhqdoUpdate(params) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/mpressliquidleak/doUpdate',
        method: 'post',
        data: params
    
    })
}

// 气体扩散模型（高斯羽烟）面
export function GaussPlumePolygonVec(params) {
    return request({
        url: `/rest/whp/qra/service/v1.0/model/GaussPlumePolygonVec?airStable=F&colums=50&dataInterval=0%2C%2030%2C%2050%2C%2070%2C%2090%2C%20150&height=40&lat=34.40937666512325&Leakagetype=2&lon=115.76745223366264&outTime=3600&q=300000&rows=25&scale=8&u=1.5&wd=220&z=60`,
        method: 'get',
    
    })
}
// // 气体扩散模型（高斯羽烟）点
// export function GaussPlumePointVec(params) {
//     return request({
//         url: `/rest/whp/qra/service/v1.0/model/GaussPlumePointVec?airStable=F&colums=50&height=40&lat=34.40937666512325&Leakagetype=2&lon=115.76745223366264&outTime=3600&q=300000&rows=25&scale=8&u=1.5&wd=220&z=60`,
//         method: 'get',
    
//     })
// }

// 气体扩散模型（高斯羽烟）点
export function GaussPlumePointVec(urls) {
    return request({
        url: urls,
        method: 'get',
    })
}

// tno刪除
export function deletetno(id) {
    return request({
        url: '/rest/whp/qra/manage/v1.0/tno/doDeleteById?id='+id,
        method: 'get',
    })
}
// 气体泄漏-断裂泄漏-绝热流动-塞流流动
export function GetGasLeakDL_JR2(query) {
    return request({
        url: '/npst/api/Leakage/GetGasLeakDL_JR2',
        method: 'get',
        params: query
    })
}
// 孔洞泄漏模型
export function GetGasLeakKD(query) {
    return request({
        url: '/npst/api/Leakage/GetGasLeakKD',
        method: 'get',
        params: query
    })
}
// 气体泄漏-断裂泄漏-绝热流动-非塞流流动
export function GetGasLeakDL_JR1(query) {
    return request({
        url: '/npst/api/Leakage/GetGasLeakDL_JR1',
        method: 'get',
        params: query
    })
}

// 气体泄漏-断裂泄漏-等温流动-非塞流流动
export function GetGasLeakDL_DW1(query) {
    return request({
        url: '/npst/api/Leakage/GetGasLeakDL_DW1',
        method: 'get',
        params: query
    })
}

// 气体泄漏-断裂泄漏-等温流动-塞流流动
export function GasLeakDL_DW2(query) {
    return request({
        url: '/npst/api/Leakage/GasLeakDL_DW2',
        method: 'get',
        params: query
    })
}

// 喷射火
export function GetFireAssessment(query) {
    return request({
        url: '/npst/api/FireAssessment/GetFireAssessment',
        method: 'get',
        params: query
    })
}


// 烟羽扩散 Pasquill-Gifford模型
export function GetTobaccoPuff(query) {
    return request({
        url: '/npst/api/GasDiffusion/GetTobaccoPuff',
        method: 'get',
        params: query
    })
}

// 烟团扩散Pasquill-Gifford模型
export function GetSmokeFeather(query) {
    return request({
        url: '/npst/api/GasDiffusion/GetSmokeFeather',
        method: 'get',
        params: query
    })
}


// TNT当量法模型
export function GetExplosion1_TNT(query) {
    return request({
        url: '/npst/api/ExplosionHazardAssessment/GetExplosion1_TNT',
        method: 'get',
        params: query
    })
}
// 丙烷当量模型
export function GetExplosion1_PE(query) {
    return request({
        url: '/npst/api/ExplosionHazardAssessment/GetExplosion1_PE',
        method: 'get',
        params: query
    })
}
// TNO多能法模型
export function GetExplosion1_TNO(query) {
    return request({
        url: '/npst/api/ExplosionHazardAssessment/GetExplosion1_TNO',
        method: 'get',
        params: query
    })
}
// 沸腾液体扩展蒸气爆炸
export function GetExplosion2E(query) {
    return request({
        url: '/npst/api/ExplosionHazardAssessment/GetExplosion2E',
        method: 'get',
        params: query
    })
}
export function UpdatCoordinate(query) {
    return request({
        url: `/rest/whp/qra/service/v1.0/model/UpdatCoordinate?coordinate=x:${query.x},y:${query.y},l:${query.l}`,
        method: 'get',
    })
}

export function GetCoordinate(query) {
    return request({
        url: `/rest/whp/qra/service/v1.0/model/GetCoordinate`,
        method: 'get',
    })
}




