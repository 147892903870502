<template>
    <div id="menu">
        <div class="list" v-for="(init, index) in lists" :key="index" @click="clicklist(init)" @mouseenter="mouseen(init)" @mouseleave="mouse(init)"
            :class="actives === init.label ? 'active' : ''">{{ init.label }}
            <div class="children" v-if="isid === init.id">
                <div class="lists" v-for="(item, index) in init.children" :key="index"
                    :class="actives2 === item.label ? 'active2' : ''" @click="clicklist2(item)">
                    {{ item.label }}
                </div>

            </div>


        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isid:null,
            actives: "信息总览",
            actives2: "",
            lists: [
                { id:1, label: "工具", path: "", children: [
                        { id:9, label: "MSDS", path: "/MSDS" },
                        { id:9, label: "地图选点", path: "/SelectingPoints" },
                    ] },
                { id:2,label: "项目管理", path: "/Project", children: [] },
                { id:3,label: "风险评估", path: "/Risk", children: [] },
            ],
        }
    },
    mounted() {
    },
    methods: {
        mouseen(data){
            console.log(data)
            if(data.children.length>0){
                this,this.isid = data.id
            }

        },
        mouse(){
            this.isid = null;
        },
        clicklist(data) {
            this.actives = data.label;
            if(this.actives2){
                this.actives2 = "";
            }
            if (data.path) {
                this.$router.push({ path: data.path })
            }

        },
        clicklist2(data) {
            let _this = this;
            setTimeout(()=>{
                _this.actives2 = data.label;
            },50)
            if (data.path) {
                this.$router.push({ path: data.path })
            }
        }
    }
}

</script>
<style scoped lang="less">
@font-face {
    font-family: YuFont;
    src: url(../../../public/common/ttf/造字工房悦圆常规体.OTF);
}

#menu {
    width: 100%;
    height: 100%;

    .list {
        margin-right: 45px;
        line-height: 70px;
        float: left;
        font-family: YuFont;
        font-size: 18px;
        cursor: pointer;
        color: #000;
        position: relative;

        .children {
            width: 128px;
            height: auto;
            border: 1px solid #4F3601;
            border-radius: 4px;
            position: absolute;
            top: 70px;
            left: -29px;
            box-shadow:0px 0px 15px #4F3601;
            .lists {
                width: 100%;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #000;
                font-size: 15px;
                margin-top: 5px;

            }

            .lists:hover {
                color: #FFB30E;
            }

            .lists:nth-child(1) {
                margin-top: 10px;
            }

            .lists:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .list:hover {
        color: #FFB30E;
        // border-bottom: 1px solid #FFB30E;
    }
}

.active {
    color: #FFB30E !important;
    // border-bottom: 1px solid #FFB30E !important;
}

.active3 {
    color: #FFB30E !important;
}

.active2 {
    color: #FFB30E !important;
}</style>