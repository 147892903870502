<!-- <template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #000;
  width: 100%;
  height: 100%;
  font-size: 0.16rem;
  user-select:none;
  /* font-size: 16px; */
}

</style> -->



<template>
  <div id="map">
    <div class="header" v-if="status">
      <div class="left">危化品事故安全风险评估系统</div>
      <div class="right">
        <!-- <el-button type="primary" :icon="AddLocation" class="xd" @click="xd"
          :title="form.x ? '经度:' + form.x + '纬度：' + form.y + '级别：' + form.l : '暂无数据'">地图选点</el-button> -->
        <menu-list></menu-list>
      </div>
    </div>
    <router-view />
    <div class="footer">备案号: <a href="https://beian.miit.gov.cn/">鲁ICP备2022019793号-1</a></div>
  </div>
  <el-dialog v-model="dialogFormVisible" title="地图选点" width="300px">
    <el-form :model="form">
      <el-form-item label="经度" :label-width="formLabelWidth">
        <el-input v-model="form.x" autocomplete="off" />
      </el-form-item>
      <el-form-item label="纬度" :label-width="formLabelWidth">
        <el-input v-model="form.y" autocomplete="off" />
      </el-form-item>
      <el-form-item label="级别" :label-width="formLabelWidth">
        <el-input v-model="form.l" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="init">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElNotification, ElMessage } from 'element-plus';
import { GeoJSON } from "ol/format";
import { AddLocation } from '@element-plus/icons-vue'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
import 'ol/ol.css'
import { GetCoordinate } from '../src/api/api.js';
import { Map, View } from 'ol'
import { Tile as TileLayer } from 'ol/layer'
import OSM from 'ol/source/OSM'
import MenuList from './views/menu/index.vue';
import { Vector as VectorSource } from 'ol/source.js';
import { Tile, Vector as VectorLayer } from 'ol/layer.js';
import XYZ from 'ol/source/XYZ';
import Draw, {
  createBox,
  createRegularPolygon,
} from 'ol/interaction/Draw.js';
import { GaussPlumePointVec } from '../src/api/api'
import { useRouter } from "vue-router";
const router = useRouter();
let status = ref(true);
let map = ref(null);
let source = ref(null);
let dialogFormVisible = ref(false);
const formLabelWidth = '50px';
let form = ref({
  x: null,
  y: null,
  l: null
})
let auto = async () => {
  let res = await GaussPlumePointVec('/rest/whp/qra/manage/v1.0/license/checking')
  console.log("%c LOG_ID: [03ab342acb26] ~ %c🚀 ~ file: App.vue:50 ~ auto ~ %c res:", 'background: green;color: white', 'background: rgba(59,14,234);color: white', 'background: rgba(229,229,19);color: black', res)
  if (res.code != 200) {
    status.value = false;
    router.push("/401");
  } else {
    router.push("/Risk");
  }
}
onMounted(async () => {
  auto();
  window.max1 = 250.9;
  window.max2 = 149.9;
  window.max3 = 824.9;
  window.max4 = 1499.9;
  window.max5 = 2999.9;
  window.max6 = 999999;
  window.min1 = -1;
  window.min2 = 151.9;
  window.min3 = 149.9;
  window.min4 = 824.9;
  window.min5 = 1499.9;
  window.min6 = 2999.9;

  // window.max1 = 10;
  // window.max2 = 100;
  // window.max3 = 0;
  // window.max4 = 0;
  // window.max5 = 0;
  // window.max6 = 0;
  // window.min1 = 0;
  // window.min2 = 0;
  // window.min3 = 0;
  // window.min4 = 0;
  // window.min5 = 0;
  // window.min6 = 0;
  var sourceMark = new XYZ({
     url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=3f66b6882d7cd61270f64e0d1a23fb83'
    // url: 'http://t4.tianditu.com/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=7786923a385369346d56b966bb6ad62f'
  })
  const raster = new TileLayer({
    source: sourceMark,
  });

  var source2 = new XYZ({
     url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=3f66b6882d7cd61270f64e0d1a23fb83'
    // url: 'http://t4.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=7786923a385369346d56b966bb6ad62f'
  })
  var tileLayer = new TileLayer({
    title: '天地图',
    source: source2
  })



  source.value = new VectorSource({ wrapX: false });

  const vector = new VectorLayer({
    source: source.value,
  });

  map.value = new Map({
    layers: [tileLayer, raster, vector],
    target: 'map',
    view: new View({
      projection: "EPSG:4326",
      center: [118.865389, 28.974276],
      zoom: 12,
    }),
  });
  const { appContext: { config: { globalProperties } } } = getCurrentInstance();
  globalProperties.$map = map.value;
  globalProperties.$source = source.value;

  //  查询是否有初始定位
  let res = await GetCoordinate()
  if (res.code == 200) {
    let str = res.result;
    const regex = /x:([^,]+),y:([^,]+),l:(\d+)/;
    const match = str.match(regex);
    if (match) {
      const x = match[1];
      const y = match[2];
      const l = match[3];
      map.value.getView().animate({
        center: [x, y],
        duration: 2000, // 飞行时间（毫秒）
        zoom: l, // 飞行时的缩放级别
      });
    } else {
      console.log("未找到匹配的值");
    }
  }
})
const xd = (() => {
  ElNotification({
    title: '提示',
    message: '请在地图上选点!',
  })

  let draw;
  let value = 'Point';
  draw = new Draw({
    source: source.value,
    type: value,
  });
  map.value.addInteraction(draw);
  draw.on('drawend', function (e) {
    let jsonObj = new GeoJSON().writeFeature(e.feature)
    var zoom = map.value.getView().getZoom();
    console.log("%c LOG_ID: [12af64a80f3e] ~ %c🚀 ~ file: App.vue:174 ~ %c zoom:", 'background: green;color: white', 'background: rgba(59,14,234);color: white', 'background: rgba(229,229,19);color: black', zoom)
    console.log("%c LOG_ID: [6fc344f9d348] ~ %c🚀 ~ file: App.vue:173 ~ %c jsonObj:", 'background: green;color: white', 'background: rgba(59,14,234);color: white', 'background: rgba(229,229,19);color: black', jsonObj)
    form.value.x = JSON.parse(jsonObj).geometry.coordinates[0];
    form.value.y = JSON.parse(jsonObj).geometry.coordinates[1];
    form.value.l = zoom;
    dialogFormVisible.value = true;
  })
});
const init = (() => {
  localStorage.setItem('form', JSON.stringify(form.value));
  ElMessage({
    message: '保存成功',
    type: 'success',
  })
  dialogFormVisible.value = false;
})

</script>

<style lang="less" scoped>
@font-face {
  font-family: YuFont;
  src: url(../public/common/ttf/造字工房悦圆常规体.OTF);
}

#map {
  width: 100%;
  height: 100%;
  background: rgb(250, 250, 250);

  .header {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 1000;
    background: #008b8b;
    // background: url(./assets/images/new/LOGO.png);
    background-size: 100% 100%;

    .left {
      width: 500px;
      height: 100%;
      font-family: YuFont;
      text-align: left;
      text-indent: 20px;
      line-height: 70px;
      font-size: 30px;
      color: sandybrown;
      float: left;
    }

    .right {
      width: calc(100% - 1250px);
      height: 100%;
      float: right;
      margin-left: 50px;
      position: relative;

      .xd {
        position: absolute;
        right: 20px;
        top: 20px
      }
    }
  }

  .footer {
    width: 100%;
    height: 15px;
    position: fixed;
    bottom: 0;
    background: #008b8b;
    color: #000;
    z-index: 1000;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    a {
      text-decoration: none;
      color: #000
    }

    ;
  }
}

.actives {
  color: #585858 !important;
}
</style>
<style>
.el-icon {
  height: 12px;
  width: 25px;
}

.commontitle-item {
  color: #409EFF;
  font-size: 16px;
  line-height: 38px;
  padding: 0 10px;
  height: 38px;
}

.chart {
  width: 100%;
  height: 400px;
  padding-left: 10px;
}

.commonline-item {
  height: 19px;
  width: 40%;
  border-bottom: 1px solid #eee;
}

.commontitle {
  color: #fff;
  height: 38px;
  background: #409EFF;
  line-height: 38px;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 18px;
}

.commonline {
  height: 38px;
  width: 80%;
  border-bottom: 1px solid #eee;
}
</style>

